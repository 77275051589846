<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="hide">
        <div class="modal-container" @click.stop>
          <div class="modal-header">
            <slot name="header">
              <h1>Cadastrar novo usuário</h1>
            </slot>
          </div>

          <form id="form-user" @submit.prevent="submit">
            <div class="modal-body">
              <slot name="body">
                <div class="field-content">
                  <label for="name">Nome</label>
                  <input type="text" id="name" v-model="name" />
                  <p class="error">{{ nameError }}</p>
                </div>
                <div class="field-content">
                  <label for="email">Email</label>
                  <input type="email" id="email" v-model="email" />
                  <p class="error">{{ emailError }}</p>
                </div>
                <!-- <div class="field-content">
                  <label for="password">Senha</label>
                  <input type="password" id="password" v-model="password" />
                  <p class="error">{{ passwordError }}</p>
                </div>
                <div class="field-content">
                  <label for="confirmPassword">Confirmar Senha</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    v-model="confirmPassword"
                  />
                  <p class="error">{{ confirmPasswordError }}</p>
                </div> -->
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <button
                  :disabled="!validateForm"
                  type="submit"
                  class="modal-default-button"
                >
                  Cadastrar
                </button>
              </slot>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "CreateUser",
  data() {
    return {
      name: "",
      email: "",
      emailError: "",
      nameError: "",
      nameIsValid: false,
      emailIsValid: false,
    };
  },
  computed: {
    validateForm() {
      return this.nameIsValid && this.emailIsValid;
    },
  },
  watch: {
    name: function (newName) {
      if (!newName.length) {
        this.nameError = "Nome é requerido!";
        this.nameIsValid = false;
      } else {
        this.nameError = "";
        this.nameIsValid = true;
      }
    },
    email: function (newEmail) {
      if (!(newEmail && /.+@.+\..+/.test(newEmail))) {
        this.emailError = "Email inválido!";
        this.emailIsValid = false;
      } else {
        this.emailError = "";
        this.emailIsValid = true;
      }
    },
    // password: function(newPassword) {
    //   if (!newPassword.length) {
    //     this.passwordError = "Senha inválida!";
    //     if (newPassword !== this.confirmPassword) {
    //       this.confirmPasswordError = "Senhas diferentes!";
    //     }
    //   } else {
    //     this.passwordError = "";
    //   }
    // },
    // confirmPassword: function(newConformPassword) {
    //   if (
    //     !(newConformPassword.length && newConformPassword === this.password)
    //   ) {
    //     this.confirmPasswordError = "Senhas diferentes!";
    //   } else {
    //     this.confirmPasswordError = "";
    //   }
    // },
  },
  methods: {
    hide() {
      setTimeout(() => {
        this.$emit("close");
      }, 0);
    },
    async submit() {
      try {
        const formData = {
          name: this.name,
          email: this.email,
        };
        const response = await this.$userService.createUser(formData);
        if (response.status === 200) {
          this.$toasted.success("Usuário cadastrado com sucesso!", {
            icon: "check",
            duration: 2000,
          });
          const user = {
            _id: response.data.user._id,
            name: response.data.user.name,
            email: response.data.user.email,
            isActive: response.data.user.isActive,
            accessLevel: response.data.user.accessLevel,
          };
          this.$emit("addUser", user);
          this.$emit("close");
        }
      } catch (error) {
        this.$toasted.error("Erro ao cadastrar usuário!", {
          duration: 2000,
        });
        console.log(error);
      }
    },
  },
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  z-index: 10000;
}

.modal-header h1 {
  margin-top: 0;
  padding: 1rem 0;
  color: #25304c;
  font-weight: bold;
  text-align: center;
  font-size: 2rem;
}

.modal-body {
  margin: 20px 0;
}

.field-content {
  padding: 0.7rem 0;
  display: flex;
  flex-direction: column;
}

.field-content > label {
  padding: 0.5rem 0;
  color: #8fa7b2;
}

.field-content > input {
  background-color: #f5f8fa;
  border: 1px solid #d3e2e5;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
}

.error {
  color: #ff0000;
  margin-top: 0.5rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0;
}

.modal-default-button {
  float: right;
  padding: 0.6rem;
  background-color: #25304c;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  width: 25%;
  transition: all 0.2s ease-in;
}

.modal-default-button:hover {
  cursor: pointer;
  background-color: #394c7e;
}

.modal-default-button:disabled {
  background-color: #394c7e;
  color: lightgray;
  cursor: not-allowed;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  .modal-container {
  width: 80%;
}
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .modal-container {
  width: 80%;
}
.modal-default-button {
  padding: 0.6rem;
  font-size: 1rem;
  width: 40%;
}


}
</style>
