<template>
  <div id="container-users">
    <div v-show="showDelete" class="dialog" @click="showDelete = false">
      <Dialog
        title="Deletar Usuário"
        @confirm="deleteUser"
        @cancel="showDelete = false"
        @close="showDelete = false"
        :options="options"
        class="deleteUserMobile"
        text="Tem certeza que quer deletar o usuario?"
      />
    </div>
    <div
      class="sidebar-users"
      :class="
        showLateralBarMobile ? `lateral-bar-mobile` : `lateral-bar-desktop`
      "
      v-click-outside="handleBarOutside"
    >
      <div class="sidebar-users__head">
        <img src="../../assets/logo.png" alt="Logo Azemute" class="logo" />
      </div>
      <span
        class="material-icons sidebar-users-buttons-actions"
        title="Voltar ao inicio"
        @click="$router.push('/home')"
      >
        home
      </span>
    </div>
    <div class="users-list">
      <CreateUser
        v-if="showModal"
        @close="showModal = false"
        @addUser="addUser"
      />
      <div class="users-list__header">
        <div class="users-list__cascate">
          <Mobilebar id="mobile-bar" @openLateralBar="handleShowSideBar" />
          <div class="users-list__title">Lista de Usuários</div>
          <div class="users-list__options">
            <span
              class="material-icons sidebar-users-buttons-actions"
              title="Fazer Logout"
              @click="logout"
            >
              logout
            </span>
          </div>
        </div>
        <div class="users-list__actions">
          <button @click="showModal = true">Novo usuário</button>
          <div class="users-list__input">
            <input type="text" v-model="search" />
            <span class="material-icons">search</span>
          </div>
        </div>
      </div>
      <div class="users-list__body">
        <table v-if="filteredUsers.length">
          <tr class="users-list__table-header">
            <th>Nome</th>
            <th>E-mail</th>
            <th>Perfil</th>
            <th>Ações</th>
          </tr>
          <tr
            class="users-list__values"
            v-for="user in filteredUsers"
            :key="user._id"
          >
            <td class="item-icon tdMobile" data-label="Nome:">
              <span
                v-if="!user.isActive"
                class="material-icons icons-btn"
                title="Usuário Bloqueado"
              >
                block </span
              >{{ user.name }}
            </td>
            <td data-label="Email:" class="tdMobile">{{ user.email }}</td>
            <td data-label="Permissão:" class="tdMobile">
              {{ user.accessLevel | permission }}
            </td>
            <td class="actions-values">
              <span
                class="material-icons white icons-btn"
                @click="switchMore(user._id)"
                @click.stop
                >more_vert</span
              >
              <div
                v-if="user._id === current_selected"
                v-show="more"
                class="selection-card"
                v-click-outside="onClickOutside"
              >
                <span
                  v-if="user.accessLevel != 1"
                  class="material-icons icons-btn"
                  @click="setModifier(user.email)"
                  title="Promover a modificador"
                >
                  person_add
                </span>
                <span
                  v-else
                  class="material-icons icons-btn"
                  @click="setComum(user.email)"
                  title="Transformar em usuario comum"
                >
                  person
                </span>
                <span
                  v-if="user.accessLevel != 0"
                  class="material-icons icons-btn"
                  @click="setAdmin(user.email)"
                  title="Transformar em administrador"
                >
                  admin_panel_settings
                </span>
                <span
                  @click="handleDelete(user._id)"
                  class="material-icons icons-btn"
                  title="Deletar usuário"
                >
                  delete
                </span>
                <span
                  v-if="user.isActive"
                  class="material-icons icons-btn"
                  @click="blockUser(user._id)"
                  title="Bloquear usuário"
                >
                  block
                </span>
                <span
                  v-else
                  class="material-icons icons-btn"
                  @click="unblockUser(user._id)"
                  title="Desbloquear usuário"
                >
                  how_to_reg
                </span>
              </div>
            </td>
          </tr>
        </table>
        <table v-else id="not-found-users">
          <h1>Nenhum Usuário Encontrado</h1>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import CreateUser from "@/components/CreateUser.vue";
import Dialog from "@/components/global/Dialog.vue";
import Mobilebar from "../../components/NavigationBar/MobileBar";

export default {
  components: { CreateUser, Dialog, Mobilebar },
  data() {
    return {
      showLateralBarMobile: false,
      users: false,
      filteredUsers: [],
      search: "",
      more: false,
      current_selected: "",
      current_deleted: "",
      showModal: false,
      showDelete: false,
      options: [
        { label: "Confirmar", event: "confirm" },
        { label: "Cancelar", event: "cancel" },
      ],
    };
  },
  watch: {
    search: function (newSearch) {
      this.handleSearch(newSearch);
    },
  },
  methods: {
    handleShowSideBar() {
      this.showLateralBarMobile = true;
      console.log(this.showLateralBarMobile);
    },
    handleBarOutside() {
      this.showLateralBarMobile = false;
    },
    onClickOutside() {
      this.more = false;
      this.current_selected = "";
    },
    switchMore(user) {
      this.more = !this.more;
      this.more ? (this.current_selected = user) : (this.current_selected = "");
    },
    hide() {
      setTimeout(() => {
        this.showModal = false;
      }, 0);
    },
    addUser(user) {
      this.users.push(user);
      this.filteredUsers = this.$userService.sortUsers(this.users, "name");
    },
    async getUsers() {
      try {
        const response = await this.$userService.getUsersList();
        if (response.status === 200) {
          this.users = response.data.users;
          this.filteredUsers = this.$userService.sortUsers(this.users, "name");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async setAdmin(email) {
      try {
        const response = await this.$userService.setAdmin(email);
        if (response.status === 200) {
          this.$toasted.success("O usuario agora é um administrador!", {
            icon: "check",
            duration: 2000,
          });
          this.getUsers();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async setModifier(email) {
      try {
        const response = await this.$userService.setModifier(email);
        if (response.status === 200) {
          this.$toasted.success("O usuario agora é um modificador!", {
            icon: "check",
            duration: 2000,
          });
          this.getUsers();
        }
      } catch (error) {
        console.log(error);
      }
    },

    async setComum(email) {
      try {
        const response = await this.$userService.setComum(email);
        if (response.status === 200) {
          this.$toasted.success("O usuario agora é comum!", {
            icon: "check",
            duration: 2000,
          });
          this.getUsers();
        }
      } catch (error) {
        console.log(error);
      }
    },

    handleDelete(id) {
      this.current_deleted = id;
      this.showDelete = true;
    },

    async deleteUser() {
      try {
        const response = await this.$userService.deleteUser(
          this.current_deleted
        );
        this.current_deleted = "";
        if (response.status === 200) {
          this.$toasted.success("Usuario deletado com sucesso!", {
            icon: "check",
            duration: 2000,
          });
          this.getUsers();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async blockUser(id) {
      try {
        const response = await this.$userService.blockUser(id);
        if (response.status === 200) {
          this.$toasted.success("Usuario bloqueado com sucesso!", {
            icon: "check",
            duration: 2000,
          });
          this.getUsers();
        }
      } catch (error) {
        console.log(error);
        this.$toasted.error("Erro ao bloquear usuario!", {
          icon: "error",
          duration: 2000,
        });
      }
    },
    async unblockUser(id) {
      try {
        const response = await this.$userService.unblockUser(id);
        if (response.status === 200) {
          this.$toasted.success("Usuario desbloqueado com sucesso!", {
            icon: "check",
            duration: 2000,
          });
          this.getUsers();
        }
      } catch (error) {
        console.log(error);
        this.$toasted.error("Erro ao desbloquear usuario!", {
          icon: "error",
          duration: 2000,
        });
      }
    },
    logout() {
      localStorage.removeItem("token");
      this.$store.dispatch("auth/clearUser");
      // this.$store.commit("auth/SET_USER", null);
      // this.$store.commit("auth/SET_TOKEN", null);
      // this.$store.commit("auth/SET_PERMISSION_LEVEL", null);
      this.$router.push("/");
    },
    handleSearch(value) {
      if (value && value.length > 0) {
        this.filteredUsers = this.users.filter((i) => {
          const val = value.toLowerCase();
          const name = i.name && i.name.toLowerCase();
          const email = i.email && i.email.toLowerCase();
          if (
            (val && name.indexOf(val) !== -1) ||
            (val && email.indexOf(val) !== -1)
          ) {
            return true;
          }
          return false;
        });
      } else {
        this.filteredUsers = this.users;
      }
    },
  },
  mounted() {
    this.getUsers();
  },
  filters: {
    permission: function (value) {
      const permissions = ["Administrador", "Modificador", "Comum"];
      return permissions[value];
    },
  },
};
</script>

<style scoped>
#container-users {
  display: flex;
}

.sidebar-users {
  width: 150px;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.sidebar-users__head {
  background: #25304c;
  height: 15%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.logo {
  height: 35%;
  width: 35%;
}

.sidebar-users-buttons-actions {
  background: #e5e5e5;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #25304c;
  font-size: 1.8rem;
}

.sidebar-users-buttons-actions:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

.users-list {
  background: #eee;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  text-align: left;
  box-sizing: border-box;
}

.item-icon {
  display: flex;
}

.item-icon > span {
  padding-right: 4px;
  font-size: 1.3rem;
  color: red;
}

.item-icon > span:hover {
  color: red;
}

.users-list__header {
  display: flex;
  flex-direction: column;
}

.users-list__cascate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #12121454;
}

.users-list__actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 32px 32px;
  background-color: #fff;
  border-radius: 8px;
  margin: 16px 0px;
}

.actions-values {
  display: flex;
}

#not-found-users {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.selection-card {
  display: flex;
  position: absolute;
  z-index: 3;
  flex-direction: column;
  justify-content: center;
  background: hsl(0, 0%, 95%);
  padding: 4px 8px;
  width: 100px;
  right: 12%;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
}
.selection-card span {
  background: #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: #626262;
  margin: 4px 0px;
  padding: 8px;
}

.users-list__title {
  font-style: normal;
  font-weight: bold;
  font-size: 37px;
  line-height: 43px;
  color: #25304c;
}

.users-list__body {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.users-list__value th {
  padding: 8px 0px;
  margin-bottom: 16px;
}

.icons-btn {
  transition: all 0.2s linear;
}

.icons-btn:hover {
  cursor: pointer;
  color: #888888c4;
}

.users-list__table-header {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #848484;
  border-bottom: 1px solid #ccc;
  margin-bottom: 8px;
}

.users-list__table-header th {
  padding: 8px 0px;
  margin-bottom: 16px;
}

.users-list__values {
  font-size: 20px;
  line-height: 23px;
  color: #888888;
}

hr {
  width: 100%;
}

button {
  width: 173px;
  height: 40px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  cursor: pointer;
  color: #ffffff;
  background: #25304c;
  border-radius: 4px;
}

button:hover {
  filter: brightness(0.8);
}

.users-list__input {
  display: flex;
  align-items: flex-end;
}

.users-list__input input {
  border-bottom: 0.8px solid rgba(0, 0, 0, 0.3);
}

#mobile-bar {
  margin-top: 4%;
  display: none;
}

@media screen and (min-width: 565px) and (max-width: 768px) {
  .sidebar-users {
    width: 13%;
    height: 100vh;
  }

  .sidebar-users__head {
    background: #25304c;
    height: 15%;
    width: 100%;
  }
  .users-list__title {
    font-size: 30px;
  }
  .users-list__values {
    font-size: 16px;
  }
  .white {
    margin-top: 5%;
  }
}

@media screen and (min-width: 480px) and (max-width: 565px) {
  #container-users {
    width: 100%;
    height: 100%;
  }
  .lateral-bar-desktop {
    display: none;
  }
  .lateral-bar-mobile {
    display: flex;
    position: fixed;
  }
  #mobile-bar {
    display: block;
    z-index: 1;
  }
  .sidebar-users {
    width: 20%;
    height: 100vh;
    z-index: 5;
  }
  .users-list__title {
    font-size: 30px;
    margin-left: 65px;
  }
  .users-list__values {
    font-size: 16px;
  }
  .white {
    margin-top: 5%;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .lateral-bar-desktop {
    display: none;
  }
  .lateral-bar-mobile {
    display: flex;
    position: fixed;
  }
  #mobile-bar {
    display: block;
    z-index: 1;
    margin-top: 6.5%;
  }
  .sidebar-users {
    width: 20%;
    height: 100vh;
    z-index: 5;
  }
  .users-list__title {
    font-size: 20px;
    margin-left: 70px;
    margin-bottom: 20px;
  }
  .users-list__values {
    display: block;
    font-size: 16px;
    margin-bottom: 5%;
  }

  table td:before {
    content: attr(data-label);
    font-weight: 550;
  }

  button {
    width: 250px;
    height: 50px;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    padding: 1px 20px;
    margin-left: -20px;
  }
  .users-list__input {
    margin-left: .5rem;
  }
  .users-list__header {
    display: flex;
    flex-direction: column;
  }
  .users-list__table-header {
    display: none;
  }
  .users-list__values {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-bottom: 2%;
    border: 1px solid #dad6eb;
    padding: 3%;
    align-items: flex-end;
  }

  .white {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .selection-card {
    position:absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 4px 10px;
    width: 200px;
    vertical-align: auto;
    margin-right: 0.5rem;
  }
  .selection-card span {
    background: #d8d8d8;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: #626262;
    margin: 4px 4px;
    padding: 8px;
  }
  .deleteUserMobile {
    z-index: 5;
  }
  .tdMobile {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1%;
  }
}
</style>
